.zap {
  font-family: "Univers", Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: $dark;
  line-height: 1.25;
  overflow-x: hidden;
  box-sizing: border-box;

  *,
  *:before,
  *:after {
    box-sizing: inherit; }

  a {
    color: $dark;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline; }

    &:visited {
      color: inherit; } }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  select,
  textarea {
    font-family: $font-univers-cd;
    font-size: 18px;
    line-height: 1;
    width: 100%;
    height: 40px;
    padding: 0 8px;
    border: 1px solid $hbs-grey;
    border-radius: 1px;

    &:focus {
      border-color: $dark; }

    &[disabled] {
      background-color: $hbs-eisblau-light; } }

  select {
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23111111' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
    -webkit-appearance: none;
    appearance: none; }

  select::ms-expand {
    display: none; }

  label {
    font-family: $font-univers-cd;
    font-size: 18px;
    line-height: 1; }

  .pi-form {

    a {
      &:hover {
        text-decoration: none; }
      &:focus {
        text-decoration: none; } } }

  .pi-form {
    display: flex;
    flex-wrap: wrap;
    >* {
      flex: 0 1 100%; } }

  .pi-form-control {
    margin-bottom: 1rem;

    a {
      display: inline-block;
      font-weight: bold;
      border-bottom: 1px solid transparent;

      &:before {
        content: "›";
        margin-right: 0.125rem; }

      &:hover,
      &:focus {
        text-decoration: none;
        border-bottom-color: $hbs-rot; } } }

  .pi-form-label {
    margin-bottom: 0.25rem; }

  .pi-form-button {
    button {
      background-color: $hbs-orange;
      display: inline-flex;
      align-items: center;
      min-height: 44px;
      padding: 0 2rem;
      font-family: $font-univers-cd;
      color: $white;
      font-size: 18px;
      border: 0; } }

  .pi-radio-control,
  .pi-checkbox-control,
  .pi-radio-control-vertical,
  .pi-checkbox-control-vertical {

    input {
      position: absolute;
      left: -999em;
      opacity: 0; }

    label {
      display: inline-block;
      padding-left: 2rem;
      margin-right: 1rem;
      position: relative;
      line-height: 1.25;

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        border: 1px solid $hbs-tuerkis;
        border-radius: 1px;
        width: 20px;
        height: 20px; } } }


  .pi-radio-control,
  .pi-radio-control-vertical {
    label:before {
      border-radius: 10px; }

    label:after {
      content: "";
      position: absolute;
      left: 4px;
      top: 4px;
      width: 12px;
      height: 12px;
      background-color: $hbs-tuerkis;
      border-radius: 6px;
      opacity: 0;
      transform: scale(0.1);
      transition: all $timing; }

    input:checked ~ label:after {
      opacity: 1;
      transform: scale(1); } }

  .pi-checkbox-control,
  .pi-checkbox-control-vertical {
    label:after {
      content: "";
      position: absolute;
      left: 1px;
      top: 1px;
      width: 18px;
      height: 18px;
      background-color: $hbs-tuerkis;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpolygon points='0 0 18 0 18 18 0 18'/%3E%3Cpolygon fill='%23FFF' fill-rule='nonzero' points='6.61 11.89 3.5 8.78 2.44 9.84 6.61 14 15.56 5.05 14.5 4'/%3E%3C/g%3E%3C/svg%3E");
      opacity: 0;
      transform: scale(0.1);
      transition: all $timing; }

    input:checked ~ label:after {
      opacity: 1;
      transform: scale(1); } }


  .pi-form-control__has-error {
    input[type="text"],
    input[type="password"],
    input[type="email"],
    select,
    textarea {
      border-color: $hbs-rot; }

    label {
      color: $hbs-rot; } }

  .pi-form-error {
    margin-top: 0.25rem;
    color: $hbs-rot;
    font-family: $font-univers-cd;
    font-size: rem(14px);
    text-align: right; }


  .pi-form-control__locked {
    input[type="text"],
    input[type="password"],
    input[type="email"],
    select,
    textarea {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M4.5,9.5 L4.5,6 C4.5,2.96243388 6.96243388,0.5 10,0.5 C13.0375661,0.5 15.5,2.96243388 15.5,6 L15.5,9.5 L17,9.5 C18.3807119,9.5 19.5,10.6192881 19.5,12 L19.5,19 C19.5,20.3807119 18.3807119,21.5 17,21.5 L3,21.5 C1.61928813,21.5 0.5,20.3807119 0.5,19 L0.5,12 C0.5,10.6192881 1.61928813,9.5 3,9.5 L4.5,9.5 Z M5.5,9.5 L14.5,9.5 L14.5,6 C14.5,3.51471863 12.4852814,1.5 10,1.5 C7.51471863,1.5 5.5,3.51471863 5.5,6 L5.5,9.5 Z M3,10.5 C2.17157288,10.5 1.5,11.1715729 1.5,12 L1.5,19 C1.5,19.8284271 2.17157288,20.5 3,20.5 L17,20.5 C17.8284271,20.5 18.5,19.8284271 18.5,19 L18.5,12 C18.5,11.1715729 17.8284271,10.5 17,10.5 L3,10.5 Z' transform='translate(2 1)'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right .75rem center; } }


  .pi-heading {
    p {
      font-family: $font-simplebold;
      text-transform: uppercase;
      color: $hbs-rot;
      font-size: rem(18px);
      line-height: 1.222;
      margin-bottom: 1.5rem; } }


  .pi-message-text,
  .pi-form-control.alert.alert-info {
    background-color: $hbs-eisblau;
    padding: 1rem 1.5rem;
    margin-bottom: 1.5rem; }

  .pi-message.pi-form-control__has-error {
    .pi-message-text {
      background-color: $hbs-rot;
      color: $white; } } }

.zap-title,
.zap-subtitle {
  font-family: $font-simplebold;
  text-transform: uppercase;
  color: $hbs-rot;
  margin: 0; }

.zap-title {
  font-size: 32px;
  line-height: 1; }

.zap-subtitle {
  font-size: 24px;
  line-height: 1;
  margin-top: 24px;
  margin-bottom: 16px; }

.zap svg {
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor; }

.zap-label {
  margin: 0;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 4px; }

.zap-input[type="text"],
.zap-input[type="password"],
.zap-input[type="email"] {
  width: 100%;
  border-radius: 1px;
  border: 1px solid $dark;
  background-color: $white;
  font-size: 15px;
  line-height: 1;
  padding: 8px;

  &:focus {
    outline: 0;
    background-color: $white;
    border: 1px solid $dark;
    box-shadow: 0px 0px 0px 2px rgba(34,34,34,0.2); } }

.zap-checkbox {

  input {
    position: absolute;
    left: -999em;
    opacity: 0; }

  label {
    display: inline-flex;
    padding-left: 32px;
    font-weight: 400;
    position: relative;
    min-height: 20px;

    &:before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 1px solid $dark;
      background-color: $white;
      border-radius: 1px;
      position: absolute;
      left: 0; } }

  input:checked + label:before {
    background-color: $hbs-tuerkis;
    border-color: $hbs-tuerkis;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='%23FFF' d='M3.75,6.6070491 L0.696145836,3.64573597 L-0.696145836,5.08153676 L3.05385416,8.71790039 C3.44174112,9.0940332 4.05825888,9.0940332 4.44614584,8.71790039 L12.6961458,0.717900393 L11.3038542,-0.717900393 L3.75,6.6070491 Z' transform='translate(4 6)'/%3E%3C/svg%3E%0A");
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center center; }

  input:focus + label:before {
    border-radius: 1px;
    box-shadow: 0px 0px 0px 2px rgba(34,34,34,0.2); } }

.zap-help {
  display: block;
  margin-top: 4px; }

.zap-alert {
  border: 1px solid $hbs-rot;
  background-color: $white;
  padding: 8px 12px;
  color: $hbs-rot;
  margin-bottom: 16px;

  > * {
    margin: 0; } }

.zap-form-control {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0; }


  &--error {
    .zap-label {
      color: $hbs-rot !important; }

    .zap-input[type="text"],
    .zap-input[type="password"],
    .zap-input[type="email"] {
      border-color: $hbs-rot !important; }

    .zap-help {
      color: $hbs-rot; } }

  &--reminder {
    display: flex;
    justify-content: space-between; } }

.zap-form-link {
  font-size: 13px;
  font-weight: bold;
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: underline; }

  &:focus {
    outline: 0;
    border-radius: 1px;
    box-shadow: 0 0 0 3px rgba($dark, 0.3); } }

.zap-toggle {
  position: fixed;
  right: 24px;
  bottom: 84px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  border: 0;
  color: $white;
  background-color: $hbs-orange;
  z-index: 2001;
  box-shadow: 0 4px 16px rgba(0,0,0,0.15);
  transition: all 0.3s ease 0s;
  cursor: pointer;

  &:hover {
    background-color: darken($hbs-orange, 5%); }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba($dark, 0.3); }

  &:active {
    transform: scale(1.25); }

  @media only screen and(max-width: 414px) {
    width: 44px;
    height: 44px;
    right: 20px;
    bottom: 84px; }

  .zap-toggle__text {
    display: none; }
  .zap-toggle__icon {
    font-size: 32px;
    display: flex; } }

.zap-btn-close {
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: transparent;
  border: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2010;
  background-color: $white;
  border-radius: 16px;
  transition: all 0.3s ease 0s;
  cursor: pointer;

  .zap-btn-close__icon {
    font-size: 24px;
    line-height: 24px;
    display: flex; }
  .zap-btn-close__text {
    display: none; }

  &:hover,
  &:focus {
    opacity: 1;
    outline: 0; }

  &:focus,
  &:active {
    transform: scale(1.25);
    box-shadow: 0 0 0 3px rgba($dark, 0.3); } }

.zap-button,
.zap .btn {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  line-height: 1;
  min-height: 44px;
  border: 0;
  font-family: $font-univers-cd;
  background-color: $hbs-orange;
  color: $white;
  padding: 0 32px;
  cursor: pointer;
  //- IE11 fix
  &:before {
    content: "";
    height: 44px; }

  .zap-button__text:after {
    content: "›";
    display: inline-block;
    margin-left: 4px; }

  &:hover,
  &:focus {
    background-color: darken($hbs-orange, 5%);

    .zap-button__text {
      text-decoration: underline; } }

  &:focus {
    outline: 0;
    box-shadow: 0px 0px 0px 2px rgba(34,34,34,0.2); } }

.zap-flyout {
  position: fixed;
  max-height: calc(100vh - 48px);
  width: 360px;
  right: 24px;
  bottom: 24px;
  z-index: 2000;
  background-color: $white;
  box-shadow: 0 4px 16px rgba(0,0,0,0.15);
  // display: none
  visibility: hidden;
  opacity: 0;
  transform: translate(100%,0);
  transition: all 0.3s ease 0s;

  @media screen and(max-width: 414px) {
    right: 10px;
    bottom: 10px;
    width: calc(100vw - 20px);
    max-height: calc(85vh - 32px); } }


.zap-flyout__scroll {
  max-height: calc(100vh - 48px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media screen and(max-width: 414px) {
    max-height: calc(85vh - 32px); } }

.zap-flyout__header {
  padding: 32px 32px 0 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and(max-width: 374px) {
    padding: 24px 16px 0 16px; }

  .zap-title {
    border-bottom: 5px solid $hbs-rot; } }

.zap-flyout__body {
  padding: 0 32px 24px 32px;

  @media screen and(max-width: 374px) {
    padding: 0 16px 16px 16px; } }

.zap-flyout__footer {
  padding: 0 32px 0 32px;

  @media screen and(max-width: 374px) {
    padding: 0 16px 0 16px; } }

.zap-shortcuts-scroll {
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 8px;
    background-image: linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0));
    z-index: 2004; } }

.zap-shortcuts-area {
  // max-height: 100px
  // overflow-y: auto
  // overflow-x: hidden
  // -webkit-overflow-scrolling: touch
  margin-bottom: 24px; }

.zap-shortcuts {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;

  &__item {
    flex: 0 0 100%;
    padding: 0 8px 8px 0;
    display: flex; } }

.zap-shortcut-link {
  font-family: $font-univers;
  font-weight: 400;
  display: flex;
  background-color: $white;
  color: $hbs-rot;
  padding: 4px 0;
  display: flex;
  flex: 0 0 100%;

  &:before {
    content: "›";
    display: block;
    width: 18px;
    height: 18px;
    text-align: center;
    flex: 0 0 24px; }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba($dark, 0.3); }

  &:hover,
  &:focus {
    text-decoration: none !important;

    .zap-shortcut-link__title {
      text-decoration: underline; } } }

.zap-text {
  margin-bottom: 16px; }

.zap-menu-link {
  background-color: transparent;
  border: 0;
  padding: 0;
  color: $dark;
  display: flex;
  align-items: center;
  padding: 4px 2px;

  + * {
    margin-top: 16px; }

  .zap-menu-link__icon {
    font-size: 18px;
    display: flex;
    flex: 0 0 auto;
    margin-right: 8px;
    margin-top: -3px; }

  &:focus {
    outline: 0;
    border-radius: 1px;
    box-shadow: 0 0 0 3px rgba($dark, 0.3); } }

// Links

.zap {
  .login-tools {
    display: flex;

    a {
      font-size: 13px;
      &:hover,
      &:focus {
        text-decoration: none; } } }

  .login-tools {
    margin-top: 1.5rem;

    a {
      display: inline-block;
      font-weight: bold;
      border-bottom: 1px solid transparent;
      margin-right: 1.5rem;

      &:before {
        content: "›";
        margin-right: 0.125rem; }

      &:hover,
      &:focus {
        text-decoration: none;
        border-bottom-color: $hbs-rot; } } } }


// Interaktivität

.zap.js-expanded {

  .zap-flyout {
    visibility: visible;
    opacity: 1;
    transform: none; }

  .zap-toggle {
    visibility: hidden;
    opacity: 0;
    transform: translate(100%,0); } }
